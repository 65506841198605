import React from "react";

const convertDate = (num) => {
  return num < 10 ? "0" + num : num;
};

const Result = ({ start, end, budget }) => {
  if (start && end && budget) {
    var dayBudget = 0,
      holidayStart = new Date(start),
      holidayEnd = new Date(end),
      todayDate = new Date(),
      arrDates = [],
      untilVacationDays = Math.round(
        Math.abs((todayDate - holidayStart) / (24 * 60 * 60 * 1000))
      ),
      difference = 1;

    if (holidayEnd < holidayStart) {
      alert("Error dates.");
      return false;
    }

    var Difference_In_Time = todayDate.getTime() - holidayStart.getTime();
    if (holidayStart < todayDate) {
      difference = Difference_In_Time / (1000 * 3600 * 24);
      difference = Math.ceil(difference);
      //    console.log("Calculate the number of days between two dates", difference);
    }

    for (
      let d = holidayStart > todayDate ? holidayStart : todayDate;
      d <= holidayEnd;
      d.setDate(d.getDate() + 1)
    ) {
      arrDates.push(
        convertDate(d.getDate()) +
          "/" +
          convertDate(d.getMonth() + 1) +
          "/" +
          d.getFullYear()
      );
    }

    if (
      arrDates[arrDates.length - 1] !==
      convertDate(holidayEnd.getDate()) +
        "/" +
        convertDate(holidayEnd.getMonth() + 1) +
        "/" +
        holidayEnd.getFullYear()
    ) {
      arrDates.push(
        convertDate(holidayEnd.getDate()) +
          "/" +
          convertDate(holidayEnd.getMonth() + 1) +
          "/" +
          holidayEnd.getFullYear()
      );
    }
    dayBudget = parseFloat(parseFloat(budget) / arrDates.length);
  } else {
    alert("All fields are mandatory.");
  }

  return (
    <>
      {untilVacationDays > 0 && (
        <span className="w-full max-w-lg mx-auto block my-5 text-base font-medium text-gray-900 dark:text-gray-300">
          &#127958; {`${untilVacationDays} days left until your Vacation.`}
        </span>
      )}

      {holidayStart.toLocaleDateString() ===
        new Date().toLocaleDateString() && (
        <span className="w-full max-w-lg mx-auto block my-5 text-base font-medium text-gray-900 dark:text-gray-300">
          &#128506; {`Today is your first day of your Trip.`}
        </span>
      )}

      {difference > 1 && (
        <span className="w-full max-w-lg mx-auto block my-5 text-base font-medium text-gray-900 dark:text-gray-300">
          &#128506; {`Today is your ${difference} day of your Trip.`}
        </span>
      )}

      <span className="w-full max-w-lg mx-auto block my-5 text-base font-medium text-gray-900 dark:text-gray-300">
        &#128176; {`${Math.floor(dayBudget)} is your budget for each day `}
        &#127965; {`For ${arrDates.length} vacation days.`}
      </span>

      <div className="mx-2">
        <div className="relative w-full max-w-lg overflow-x-auto shadow-md rounded-lg mx-auto mt-5 pb-5">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Day
                </th>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Budget
                </th>
              </tr>
            </thead>
            <tbody>
              {arrDates.map((element, i) => (
                <tr
                  key={i}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    {i + difference}
                  </th>
                  <td className="px-6 py-4">{element}</td>
                  <td className="px-6 py-4">{Math.floor(dayBudget)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Result;
