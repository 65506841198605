import React, { useState, useEffect } from "react";
import Form from "./Form";
import Result from "./Result";

function App() {
  const [start, setStart] = useState(""),
    [end, setEnd] = useState(""),
    [budget, setBudget] = useState("");

  const setData = (s, e, b) => {
    setStart(s);
    setEnd(e);
    setBudget(b);

    localStorage.setItem("start", JSON.stringify(s));
    localStorage.setItem("end", JSON.stringify(e));
    localStorage.setItem("budget", JSON.stringify(b));
  };

  useEffect(() => {
    const s = JSON.parse(localStorage.getItem("start")),
      e = JSON.parse(localStorage.getItem("end")),
      b = JSON.parse(localStorage.getItem("budget"));

    setStart(s);
    setEnd(e);
    setBudget(b);
  }, []);

  return (
    <div className="App bg-white dark:bg-black min-h-screen">
      <h1 className="text-3xl block py-5 font-medium text-gray-900 dark:text-gray-300 text-center">
        &#128176; Budget
      </h1>
      <Form start={start} end={end} budget={budget} setData={setData} />

      {start && end && budget && (
        <Result start={start} end={end} budget={budget} />
      )}
    </div>
  );
}

export default App;
