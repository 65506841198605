import React from "react";

function Form({ start, end, budget, setData }) {
  const submitForm = e => {
    e.preventDefault();

    const sDate = e.target.start.value,
      eDate = e.target.end.value,
      b = e.target.budget.value;

    // console.log("s", sDate);
    // console.log("e", eDate);
    // console.log("b", b);

    setData(sDate, eDate, b);
  };

  return (
    <div className="mx-2">
      <form
        className="w-full max-w-lg dark:bg-slate-900 rounded-lg px-6 py-8 ring-1 ring-slate-900/5 shadow-xl mx-auto"
        onSubmit={submitForm}
      >
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              htmlFor="start"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Start date
            </label>
            <input
              type="date"
              name="start"
              id="start"
              defaultValue={start}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="name@flowbite.com"
              required
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label
              htmlFor="end"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              End date
            </label>
            <input
              type="date"
              name="end"
              id="end"
              defaultValue={end}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              htmlFor="budget"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Budget
            </label>

            <input
              type="text"
              name="budget"
              id="budget"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              defaultValue={budget}
            />
          </div>
          <div className="w-full md:w-1/2 px-3 text-center">
            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-7 text-center"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Form;
